<template>
    <detalhe titulo="Código" :size="size">
        {{ participante?.codigoExterno }}
    </detalhe>
    <detalhe titulo="Nome" :size="size">
        {{ participante?.nome }}
    </detalhe>
    <detalhe titulo="CPF" :size="size">
        {{ participante?.cpf }}
    </detalhe>
    <detalhe titulo="CNPJ" :size="size">
        {{ participante?.cnpj }}
    </detalhe>
    <detalhe titulo="Email" :size="size">
        {{ participante?.email }}
    </detalhe>
    <detalhe titulo="Telefone" :size="size">
        {{ participante?.telefone }}
    </detalhe>
    <detalhe titulo="Data de Nascimento" :size="size">
        {{ $dateFormat(participante?.dataNascimento, 'DD/MM/YYYY') }}
    </detalhe>
    <detalhe titulo="Gênero" :size="size">
        {{ participante?.genero }}
    </detalhe>
    <detalhe titulo="Situação" :size="size">
        <status :status="participante?.statusIntegracaoExternaDescr"></status>
    </detalhe>
    <detalhe titulo="Status PF" :size="size">
        <status :status="participante?.statusIntegracaoInternaPfDescr"></status> 
        <span class="ml-2" v-if="participante?.codStatusIntegracaoPf">{{ participante?.descricaoStatusIntegracaoPf }}</span>
    </detalhe>
    <detalhe titulo="Status PJ" :size="size">
        <status :status="participante?.statusIntegracaoInternaPjDescr"></status>
        <span class="ml-2" v-if="participante?.codStatusIntegracaoPj">{{ participante?.descricaoStatusIntegracaoPj }}</span>
    </detalhe>
    <detalhe titulo="Status Vínculo PF/PJ" :size="size">
        <status :status="participante?.statusVinculoPjPfDescr"></status>
        <span class="ml-2" v-if="participante?.codStatusVinculoPjPf">{{ participante?.descricaoStatusVinculoPjPf }}</span>
    </detalhe>
    <detalhe titulo="Status Evento" :size="size">
        <status :status="participante?.statusIntegracaoInternaEventoDescr"></status>
        <span class="ml-2" v-if="participante?.codStatusIntegracaoEvento">{{ participante?.descricaoStatusIntegracaoEvento }}</span>
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(participante?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
</template>

<script>
export default {
    props: {
        participante: {
            type: Object,
        },
    },

    data() {
        return {
            size: '140',
        };
    },
};
</script>
